import React, { useEffect, useState } from "react";
import axios from "axios";
import Paginate from "../../components/shared/Paginate";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";
import DeviceRow from "../../components/device/DeviceRow";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DevicesPage = () => {
  // Set Up State Hooks
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isLocked, setIsLocked] = useState("");
  const [platform, setPlatform] = useState("");

  // Fetch DEVICE on Component Mount
  useEffect(() => {
    const fetchDevices = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/devices",
          {
            params: {
              search: searchQuery,
              page: currentPage,
              is_locked: isLocked,
              platform: platform,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setDevices(response.data.content.data);

          setCurrentPage(currentPage);
          setTotalPages(response.data.content.total);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch Devices");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchDevices();
  }, [searchQuery, currentPage, isLocked, platform]); // Re-fetch when filters change

  // Update lock status
  const handleUpdateLockStatus = async (deviceId, newLockStatus, lockedAt) => {
    // e.preventDefault();

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/devices/${deviceId}`,
        {
          is_locked: newLockStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful edit
      if (response.data.result === "success") {
        // Update the devices state to reflect the change
        setDevices(response.data.content.data);
        setTotalPages(response.data.content.total);
        setCurrentPage(currentPage);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update. Please try again.");
      // Revert the toggle if the update failed
      setDevices(
        devices.map((device) =>
          device.id === deviceId ? { ...device } : device
        )
      );
    }
  };

  // Handle filter changes
  const handleFilterChange = (filterType, value) => {
    setCurrentPage(1); // Reset to first page when filters change
    if (filterType === "platform") {
      setPlatform(value);
    } else if (filterType === "isLocked") {
      setIsLocked(value);
    }
  };

  // Render the Component
  return (
    <div className="device-page">
      <h3 className="my-5">Devices</h3>

      {/* Filter Section */}
      <Row className="mb-3">
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Platform</Form.Label>
            <Form.Select
              value={platform}
              onChange={(e) => handleFilterChange("platform", e.target.value)}
            >
              <option value="">All Platforms</option>
              <option value="android">Android</option>
              <option value="ios">iOS</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Lock Status</Form.Label>
            <Form.Select
              value={isLocked}
              onChange={(e) => handleFilterChange("isLocked", e.target.value)}
            >
              <option value="">All Status</option>
              <option value="1">Locked</option>
              <option value="0">Unlocked</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Body */}
          <table className="table table-responsive">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>ID</th>
                <th style={{ width: "5%" }}>QR ID</th>
                <th style={{ width: "10%" }}>Device Name</th>
                <th style={{ width: "10%" }}>Platform</th>
                <th style={{ width: "5%" }}>Failed Attempts</th>
                <th style={{ width: "5%" }}>Scan Attempts</th>
                <th style={{ width: "10%" }}>Scanned At</th>
                <th style={{ width: "10%" }}>App Version</th>
                <th style={{ width: "5%" }}>Lock Status</th>
                <th style={{ width: "10%" }}>Locked At</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <DeviceRow
                  key={device.id}
                  device={device}
                  onUpdateLockStatus={handleUpdateLockStatus}
                />
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-center">
            <nav aria-label="Page navigation" className="overflow-auto">
              <Paginate
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default DevicesPage;
