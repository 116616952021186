import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const EditSettingModal = ({
  showEditModal,
  handleCloseEditForm,
  currentOpenedItem,
  handleEditFormSubmit,
  editFormData,
  handleEditFormChange,
  editFormError,
  setEditFormError,
  isLoadingSpin,
}) => {
  return (
    <Modal show={showEditModal} onHide={() => handleCloseEditForm()}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Setting ({currentOpenedItem.id})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          <b>Key: </b>
          {currentOpenedItem.key}
        </Form.Label>
        <hr />
        <Form onSubmit={handleEditFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={editFormData.title}
              onChange={handleEditFormChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Value</Form.Label>
            <Form.Control
              type="text"
              name="value"
              value={editFormData.value}
              onChange={handleEditFormChange}
              required
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between align-items-center mt-4 mb-4">
            <Form.Label>Show in login config response</Form.Label>
            <Form.Switch
              name="show_in_config"
              value={editFormData.show_in_config}
              onChange={handleEditFormChange}
              checked={editFormData.show_in_config}
            />
          </Form.Group>
          {editFormError && (
            <div className="alert alert-danger">{editFormError}</div>
          )}
          <hr />
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button variant="primary" type="submit" disabled={isLoadingSpin}>
              Update
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditSettingModal;
