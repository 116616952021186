import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const FaqRow = ({ index, faq, onEdit, onDelete }) => {
  return (
    <tr key={index}>
      <td>{faq.id}</td>
      <td>{faq.question}</td>
      <td style={{whiteSpace: "break-word"}}>{faq.answer}</td>
      <td>
        {
          // eslint-disable-next-line
          faq.published == 1 ? "Yes" : "No"
        }
      </td>
      <td>
        {/* ====== EDIT BUTTON + MODAL ====== */}
        <Button
          variant="secondary"
          className="btn-sm me-2"
          onClick={() => onEdit(faq)}
          aria-label="Edit"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>

        <button
          className="btn btn-danger btn-sm"
          onClick={() => onDelete(faq.id)}
          aria-label="Delete"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

export default FaqRow;
