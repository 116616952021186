// DeviceRow.js
import React from "react";
import Form from "react-bootstrap/Form";

const DeviceRow = ({ device, onUpdateLockStatus }) => {
  const handleToggleLock = (e) => {
    const newLockStatus = e.target.checked ? 1 : 0;
    const lockedAt = newLockStatus ? device.locked_at : null;

    onUpdateLockStatus(device.id, newLockStatus, lockedAt);
  };

  return (
    <tr>
      <td>{device.id}</td>
      <td>{device.qrcode_id}</td>
      <td style={{ whiteSpace: "break-word" }}>{device.device_name}</td>
      <td>{device.platform}</td>

      <td>{device.failed_attempts}</td>
      <td>{device.scan_counter}</td>
      <td>{device.last_scan_at}</td>
      <td>{device.app_version}</td>
      <td>
        <Form.Check
          type="switch"
          id={`lock-switch-${device.id}`}
          checked={device.is_locked === 1}
          onChange={handleToggleLock}
        />
      </td>
      <td>{device.locked_at}</td>
    </tr>
  );
};

export default DeviceRow;
