import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import QRRow from "../../components/qr/QRRow";
import Paginate from "../../components/shared/Paginate";
import EditQRModal from "../../components/qr/EditQRModal";
import BulkGenerateModal from "../../components/qr/BulkGenerateModal";
import NewQRModal from "../../components/qr/NewQRModal";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";

function isset(ref) {
  return typeof ref !== "undefined";
}

const QrcodesPage = () => {
  // Set Up State Hooks
  const [qrCodes, setQrCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [isProduction, setIsProduction] = useState(false);
  const [modalError, setModalError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    category_id: null,
    assigned: null,
    printed: null,
    production: null,
    all: null,
    page: null,
    page_size: null,
  });
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedQRCodes, setSelectedQRCodes] = useState([]);
  const categories = JSON.parse(localStorage.getItem("categories"));
  const passcode_types = JSON.parse(localStorage.getItem("passcode_types"));
  // const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [isDeleteBtnSpin, setIsDeleteBtnSpin] = useState(false);
  const [isExportBtnSpin, setIsExportBtnSpin] = useState(false);
  const [isNewQrSpin, setIsNewQrSpin] = useState(false);

  // NEW QR Form state
  const [showNewQRForm, setShowNewQRForm] = useState(false);
  const [newQRCategory, setNewQRCategory] = useState("");
  const [newQRCode, setNewQRCode] = useState("");

  // Bulk Generate Form state
  const [showBulkGenerateModal, setShowBulkGenerateModal] = useState(false);
  const [bulkCategory, setBulkCategory] = useState("");
  const [bulkCount, setBulkCount] = useState("");

  // Edit & Delete options states
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentOpenedQRCode, setCurrentOpenedQRCode] = useState({});
  const [editFormData, setEditFormData] = useState({
    id: null,
    category_id: "",
    passcode_type: "",
    passcode: null,
    code: "",
    is_production: false,
  });

  const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
  const perPageOptions = [10, 25, 50, 100];

  const [editFormError, setEditFormError] = useState("");

  // Fetch QR Codes on Component Mount

  const fetchQRCodes = async () => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/admin/qrcodes",
        {
          params: {
            ...filters,
            page: currentPage,
            page_size: itemsPerPage,
            search: searchQuery,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === "success") {
        setQrCodes(response.data.content.data);
        setTotalPages(response.data.content.last_page);
        setTotalCount(response.data.content.total);
        setLoading(false);
      } else {
        console.log(response.data);

        throw new Error("Failed to fetch QR codes");
      }
    } catch (error) {
      // setError(error.message || "An error occurred");
      // setLoading(false);
      if (isset(error.response.data.error_description[0])) {
        alert(error.response.data.error_description[0]);
      } else {
        console.log(error.response);
      }

      if (
        error.response.data.error_code === 401 &&
        window.location.pathname !== "/"
      ) {
        removeToken();
        redirectToHome();
      }
    }
  };

  useEffect(() => {
    fetchQRCodes();
    setSelectAll(false);
  }, [filters, currentPage, itemsPerPage, searchQuery]); // Re-fetch when filters change

  const handlePerPageChange = (e) => {
    const newPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
    setFilters((prev) => ({ ...prev, page_size: newPerPage }));
  };

  // Implement Checkbox Selection
  // const handleSelectQRCode = (id) => {
  //   setSelectedQRCodes((prevSelected) =>
  //     prevSelected.includes(id)
  //       ? prevSelected.filter((qrId) => qrId !== id)
  //       : [...prevSelected, id]
  //   );
  // };

  const handleSelectQRCode = (id) => {
    setSelectedQRCodes((prevSelected) => {
      const newSelected = prevSelected.includes(id)
        ? prevSelected.filter((qrId) => qrId !== id)
        : [...prevSelected, id];

      // Check if all current page items are selected
      const currentPageIds = qrCodes.map((qr) => qr.id);
      const allCurrentSelected = currentPageIds.every((id) =>
        newSelected.includes(id)
      );
      setSelectAll(allCurrentSelected);

      return newSelected;
    });
  };

  // Implement Filter Handling
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
      page: 1, // Reset page to 1 whenever a filter changes
    }));
    setCurrentPage(1); // Reset currentPage to 1 whenever a filter changes
    setSelectedQRCodes([]);
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const currentPageIds = qrCodes.map((qr) => qr.id);
      setSelectedQRCodes((prevSelected) => {
        const newSelected = new Set([...prevSelected, ...currentPageIds]);
        return Array.from(newSelected);
      });
    } else {
      const currentPageIds = qrCodes.map((qr) => qr.id);
      setSelectedQRCodes((prevSelected) =>
        prevSelected.filter((id) => !currentPageIds.includes(id))
      );
    }
  };

  // Export to PDF Button
  const exportToPDF = async () => {
    setIsExportBtnSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/admin/qrcodes/export",
        {
          code_ids: selectedQRCodes.length > 0 ? selectedQRCodes : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      // Check for a PDF response based on the 'Content-Type' header
      const contentType = response.headers["content-type"];
      console.log(response.data);

      if (contentType === "application/pdf") {
        // Create a URL for the blob
        const fileToDownload = new Blob([response.data], {
          type: "application/pdf",
        });
        const url = window.URL.createObjectURL(fileToDownload);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        let filename = "qrcodes.pdf";

        if (contentDisposition) {
          const match = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (match && match[1]) {
            // Remove quotes if present
            filename = match[1].replace(/['"]/g, "");
          }
        }

        console.log("filename " + filename);
        console.log("contentDisposition " + contentDisposition);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // Reset selectedQRCodes after successful download
        setSelectedQRCodes([]);
        fetchQRCodes();
        // Clean up
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        console.log(response.data);
        alert(error.response.data.error_description[0] || "Export failed");
        // Assuming an error, try to read as text to get the JSON message
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            console.error("Error downloading PDF:", errorResponse);
            alert(
              errorResponse.error_description.join("\n") || "An error occurred."
            );
          } catch (e) {
            console.error("Failed to parse error response:", e);
            alert("An unknown error occurred.");
          }
        };
        reader.readAsText(response.data);
      }
    } catch (error) {
      // Axios throws an error for non-2xx responses. Handle it here.
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx and possibly contains a blob
        const errorData = error.response.data;

        // Since we expect a JSON for errors, convert the blob to text
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            alert(
              `${
                errorResponse.error_description.join("\n") ||
                "An error occurred."
              }`
            );
          } catch (e) {
            // Not JSON or not readable as such
            alert("An unknown error occurred.");
          }
        };
        reader.onerror = () => {
          alert("An error occurred while reading the error response.");
        };
        reader.readAsText(errorData);
      } else {
        // The request was made but no response was received or an error occurred in setting up the request
        console.log(error.message);
        alert("An error occurred, and no server response was received.");
      }
    } finally {
      setIsExportBtnSpin(false);
      setCurrentPage(1); // Reset currentPage to 1 whenever a filter changes
    }
  };

  // Implement search Filter Handling
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  // Handle NewQR Submit
  const handleNewQRSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsNewQrSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes`,
        {
          category_id: newQRCategory,
          code: newQRCode,
          is_production: Boolean(isProduction),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: 1, // Set `page` to 1, keeping all other fields unchanged
        category_id: null,
        assigned: null,
        printed: null,
      }));

      // Handle successful submission
      setShowNewQRForm(false);
      setNewQRCategory("");
      setNewQRCode("");
      setIsProduction(false);
      setLoading(false);
      // Optionally, refresh the list of QR codes or add the new QR code to the state directly
    } catch (modalError) {
      // Handle errors, e.g., show an error message
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsNewQrSpin(false);
      // Reset selectedQRCodes after successful download
      setSelectedQRCodes([]);
      fetchQRCodes();
    }
  };

  // Handle Bulk Generate QR
  const handleBulkGenerateSubmit = async (e) => {
    e.preventDefault();
    setIsNewQrSpin(true); // Start loading

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/bulk/generate`,
        {
          category_id: bulkCategory,
          count: bulkCount,
          is_production: isProduction,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === "success") {
        setSelectedQRCodes([]);
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: 1, // Set `page` to 1, keeping all other fields unchanged
          category_id: null,
          assigned: null,
          printed: null,
          production: null,
        }));
        setQrCodes(response.data.content.data);
        setTotalPages(response.data.content.last_page);

        // Handle successful response
        setShowBulkGenerateModal(false); // Close the modal
        setBulkCategory(""); // Reset the form fields
        setBulkCount("");
        setIsProduction(false);
        // Optionally refresh the QR codes list here
        setLoading(false);
        fetchQRCodes();
      }
    } catch (modalError) {
      // Handle errors
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsNewQrSpin(false); // End loading
    }
  };

  // Handle Bulk Delete
  const handleBulkDelete = async () => {
    if (selectedQRCodes.length < 1) {
      alert("No QR code selected!");
    } else {
      if (
        window.confirm("Are you sure you want to delete the selected QR codes?")
      ) {
        setIsDeleteBtnSpin(true); // Start loading
        const token = getToken();
        if (!token) {
          removeToken();
          redirectToHome();
        }
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/bulk/delete`,
            {
              params: { ids: selectedQRCodes },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Refresh data or handle success
          if (response.data.result === "success") {
            setSelectedQRCodes([]);
            setFilters((prevFilters) => ({
              ...prevFilters,
              page: 1, // Set `page` to 1, keeping all other fields unchanged
              category_id: null,
              assigned: null,
              printed: null,
            }));
            setQrCodes(response.data.content.data);
            setTotalPages(response.data.content.last_page);
            setLoading(false);
            fetchQRCodes();
          }
        } catch (error) {
          // Handle errors
          alert(
            error.response.data.error_description[0] || "An error occurred"
          );
        } finally {
          setIsDeleteBtnSpin(false);
        }
      }
    }
  };

  // Handle Edit QR Form Changes
  const handleEditFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle Edit QR Form Changes
  const handleIsProductionChange = (e) => {
    setIsProduction(e.target.checked);
  };
  const handleShowEditModal = (qrCode) => {
    setCurrentOpenedQRCode(qrCode); // Store the selected QR code's data
    setShowEditModal(true);
    setEditFormError("");
    setEditFormData({
      id: qrCode.id,
      category_id: qrCode.category_id,
      passcode_type: qrCode.passcode_type,
      passcode: qrCode.passcode || null,
      is_production: Boolean(qrCode.is_production),
    });
  };

  // Handle Edit Form Submit
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    // Validation
    if (
      !editFormData.id ||
      !editFormData.category_id ||
      // !editFormData.passcode_type ||
      // !editFormData.passcode.match(/^[0-9]{1,4}$/)
      (editFormData.passcode_type === "Passcode" &&
        !editFormData.passcode.match(/^[0-9]{1,4}$/))
    ) {
      setEditFormError("Please fill in all required fields correctly.");
      return;
    }

    // Start with required data
    let requestData = {
      category_id: editFormData.category_id,
      passcode_type: editFormData.passcode_type,
      is_production: editFormData.is_production,
    };
    // Conditionally add passcode if it's not null
    if (editFormData.passcode_type === "Passcode") {
      requestData.passcode = editFormData.passcode;
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/${editFormData.id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful edit
      setShowEditModal(false); // Close the modal
      // Optionally refresh your list of QR codes here
      if (response.data.result === "success") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: currentPage, // Set `page` to 1, keeping all other fields unchanged
        }));
        setQrCodes(response.data.content.data);
        setTotalPages(response.data.content.last_page);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating QR code:", error.response);
      setEditFormError(error.response.data.error_description[0]);
    }
  };

  // Handle Delete QR
  const handleDeleteQR = async (id) => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    if (window.confirm("Are you sure you want to delete this QR code?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.result === "success") {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page: currentPage, // Set `page` to 1, keeping all other fields unchanged
          }));
          setQrCodes(response.data.content.data);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        }
      } catch (error) {
        console.error("Delete failed", error);
        alert(error.response.data.error_description[0]);
      }
    }
  };

  // Handle Delete QR
  const resetEmail = async (id) => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/email/reset`,
        {
          qrcode_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === "success") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: currentPage, // Set `page` to 1, keeping all other fields unchanged
        }));

        setLoading(false);
      }
    } catch (error) {
      console.error("Reset email failed", error);
      alert(error.response.data.error_description[0]);
    }
  };

  // Render the Component
  return (
    <div className="qrcodes-page">
      <h3 className="my-5">QR Codes</h3>

      <div className="d-flex flex-row justify-content-between">
        <div>
          {/* ========New QR BUTTON ========= */}
          <Button
            id="new-qr-btn"
            className="w-md-auto"
            variant="secondary"
            onClick={() => setShowNewQRForm(true)}
          >
            New QR
          </Button>
          <NewQRModal
            showNewQRForm={showNewQRForm}
            setShowNewQRForm={setShowNewQRForm}
            handleNewQRSubmit={handleNewQRSubmit}
            newQRCategory={newQRCategory}
            setNewQRCategory={setNewQRCategory}
            categories={categories}
            newQRCode={newQRCode}
            setNewQRCode={setNewQRCode}
            modalError={modalError}
            setModalError={setModalError}
            isNewQrSpin={isNewQrSpin}
            isProduction={isProduction}
            handleIsProductionChange={handleIsProductionChange}
          />

          {/* ==== Generate Bulk QR ==== */}
          <Button
            className="ms-3 w-md-auto"
            variant="secondary"
            onClick={() => setShowBulkGenerateModal(true)}
          >
            Generate Bulk QR
          </Button>
          <BulkGenerateModal
            showBulkGenerateModal={showBulkGenerateModal}
            setShowBulkGenerateModal={setShowBulkGenerateModal}
            handleBulkGenerateSubmit={handleBulkGenerateSubmit}
            bulkCategory={bulkCategory}
            setBulkCategory={setBulkCategory}
            categories={categories}
            bulkCount={bulkCount}
            setBulkCount={setBulkCount}
            modalError={modalError}
            isNewQrSpin={isNewQrSpin}
            setModalError={setModalError}
            isProduction={isProduction}
            handleIsProductionChange={handleIsProductionChange}
          />
        </div>
        <input
          type="text"
          name="search"
          onChange={handleSearchChange}
          className="form-control input-filters"
          placeholder="Type here to search"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Filters */}
          <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
            <select
              name="category_id"
              onChange={handleFilterChange}
              value={filters.category_id}
              className="form-select input-filters"
            >
              <option value="">Filter By Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
            {/* New Printed Switch */}
            <select
              name="printed"
              onChange={handleFilterChange}
              value={filters.printed}
              className="form-select input-filters"
            >
              <option value="">Filter By Printed</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            {/* New Assigned Switch */}
            <select
              name="assigned"
              onChange={handleFilterChange}
              value={filters.assigned}
              className="form-select input-filters"
            >
              <option value="">Filter By Assigned</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            {/* New production Switch */}
            <select
              name="production"
              onChange={handleFilterChange}
              value={filters.production}
              className="form-select input-filters"
            >
              <option value="">Filter By Production</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>

            {/* Bulk Delete */}
            <button
              id="bulk-delete-btn"
              className="btn btn-danger me-md-2 input-filters"
              onClick={handleBulkDelete}
              disabled={isDeleteBtnSpin}
            >
              {isDeleteBtnSpin ? "Deleting..." : "Bulk Delete"}
              {/* Bulk Delete */}
              {isDeleteBtnSpin && <span className="spinner-inline"></span>}
            </button>

            <button
              id="export-pdf-btn"
              className="btn btn-secondary input-filters"
              style={{ cursor: "pointer", float: "right" }}
              onClick={exportToPDF}
              disabled={isExportBtnSpin}
            >
              {isExportBtnSpin ? "Exporting..." : "Export to PDF"}
              {isExportBtnSpin && <span className="spinner-inline"></span>}
            </button>
          </div>
          {/* Body */}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>ID</th>
                  <th>Code</th>
                  <th>User Email</th>
                  <th>Passcode Type</th>
                  <th>Category</th>
                  <th>Assigned</th>
                  <th>Printed</th>
                  <th>Scanned</th>
                  <th>isProduction</th>
                  <th>QR/Barcode Image</th>
                  {/* <th>Barcode Image</th> */}
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {qrCodes.map((qrCode) => (
                  <QRRow
                    key={qrCode.id}
                    qrCode={qrCode}
                    onEdit={handleShowEditModal}
                    onDelete={handleDeleteQR}
                    resetEmail={resetEmail}
                    onChange={handleSelectQRCode}
                    isSelected={selectedQRCodes.includes(qrCode.id)}
                  />
                ))}
                {/* Edit Modal */}
                <EditQRModal
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  currentOpenedQRCode={currentOpenedQRCode}
                  handleEditFormSubmit={handleEditFormSubmit}
                  editFormData={editFormData}
                  handleEditFormChange={handleEditFormChange}
                  categories={categories}
                  passcode_types={passcode_types}
                  editFormError={editFormError}
                  isProduction={isProduction}
                  setIsProduction={setIsProduction}
                />
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <label htmlFor="perPageSelect" className="me-2">
                Items per page:
              </label>
              <select
                id="perPageSelect"
                value={itemsPerPage}
                onChange={handlePerPageChange}
                className="form-select form-select-sm d-inline-block w-auto"
              >
                {perPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div>
              Found <span className="badge bg-secondary">{totalCount}</span>{" "}
              items
            </div>
            <div className="d-flex justify-content-center">
              <nav aria-label="Page navigation">
                <ul className="pagination flex-wrap">
                  <Paginate
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    className="pagination-sm flex-wrap"
                  />
                </ul>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QrcodesPage;
