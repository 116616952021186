import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

const NavMenuItem = ({ to, label, icon }) => {
  const iconStyle = {
    marginRight: '8px',
    width: '1.25em',
    height: '1.25em',
  };
  return (
    <li className="nav-item">
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
      >
        <FontAwesomeIcon className="nav-icon" icon={icon} style={iconStyle} /> {label}
      </NavLink>
    </li>
  );
};

export default NavMenuItem;
