import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const SocialRow = ({ link, onEdit, onDelete }) => {
  return (
    <tr key={link.id}>
      <td>{link.id}</td>
      <td>
        {link.provider.charAt(0).toUpperCase() + link.provider.slice(1)}
      </td>
      <td>{link.url}</td>
      <td>
        {/* ====== EDIT BUTTON + MODAL ====== */}
        <Button
          variant="secondary"
          className="btn-sm me-2"
          onClick={() => onEdit(link)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>

        <button
          className="btn btn-danger btn-sm"
          onClick={() => onDelete(link.id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

export default SocialRow;
