import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { login, syncCategories } from "../services/apiService";
import { retryAsyncFunction } from "../utils/retryUtility";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Added state for password visibility
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  const handleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoadingSpin(true); // Start loading
    try {
      const response = await login(email, password);

      if (response.data.result === "success") {
        const token = response.data.content.token;
        const tokenExpireStr = response.data.content.token_expire;

        const tokenExpireDate = new Date(tokenExpireStr);
        const tokenExpireMillis = tokenExpireDate.getTime();

        // Store the token expire time in milliseconds in localStorage
        localStorage.setItem("token_expire", tokenExpireMillis.toString());

        localStorage.setItem("token", token);

        localStorage.setItem("scan_token", response.data.content.scan_token);
        localStorage.setItem(
          "categories",
          JSON.stringify(response.data.content.config.categories)
        );
        localStorage.setItem(
          "settings",
          JSON.stringify(response.data.content.config.settings)
        );
        localStorage.setItem(
          "passcode_types",
          JSON.stringify(response.data.content.config.passcode_types)
        );
        // Navigate to the Dashboard page
        navigate("/dashboard");

        if (token) {
          performSyncCategories(token);
        }
      } else {
        alert("Login failed");
      }
    } catch (error) {
      if (error.response) {
        // console.log(error.response);
        const errorMessages = error.response.data.error_description.join(" ");
        setErrorMessage(errorMessages || "An unexpected error occurred.");
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
        setErrorMessage(
          "The server is not responding. Please try again later."
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        setErrorMessage("An unexpected error occurred.");
      }
    } finally {
      setIsLoadingSpin(false); // Start loading
    }
  };

  const performSyncCategories = (token) => {
    retryAsyncFunction(() => syncCategories(token))
      .then(() => console.log("Categories synced successfully"))
      .catch((error) =>
        console.error("Failed to sync categories after retries: ", error)
      );
  };
  return (
    <div className="container-fluid h-100 d-flex justify-content-center align-items-center min-vh-100 div0 custom-background">
      <div className="row text-light w-100 mb-5 mb-md-0 div1 ">
        <div className="col-md-6 col-lg-4 mx-auto div2">
          <div className="card shadow-lg border-0 rounded-20 px-2 p-md-4 div4">
            <div className="card-body">
              <img
                src={process.env.PUBLIC_URL + "/logo2.png"}
                alt="App Logo"
                className="logo mb-3 mx-auto d-block"
                width="100"
                height="100"
              />
              <h5 className="card-title text-center mb-5">QRystal</h5>
              <form onSubmit={handleLogin}>
                <div className="form-group mb-3">
                  <label
                    className="form-label fw-bold text-left"
                    htmlFor="email"
                  >
                    Email or Username
                  </label>
                  <input
                    type="text"
                    className="form-control login-form"
                    id="email"
                    placeholder="Enter email or username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label
                    className="form-label fw-bold text-left"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="input-group rounded-10 border-primary shadow-none position-relative">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control login-form"
                      id="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="on"
                    />
                    <button
                      id="show_btn"
                      type="button"
                      className="btn btn-sm btn-outline-secondary position-absolute"
                      style={{ right: "0px" }}
                      onClick={handleShowPassword}
                    >
                      <FontAwesomeIcon
                        icon={isPasswordVisible ? faEye : faEyeSlash}
                      />
                    </button>
                  </div>
                  <Tooltip data-tip="Show/Hide Password" place="right" />
                </div>
                <div className="container mt-1 p-0">
                  {/* Other form elements */}
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                </div>
                {/* Form submission button */}
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="login-btn btn btn-secondary w-100 fw-bold float-right mt-4 shadow-none rounded-10"
                    disabled={isLoadingSpin}
                  >
                    Login
                    {isLoadingSpin && <span className="spinner-inline"></span>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
