import { Modal, Button, Form } from "react-bootstrap";

const AddModal = ({
  showNewForm,
  handleCloseAddForm,
  handleAddSubmit,
  handleNewFormChange,
  modalError,
  setModalError,
  isLoadingSpin,
}) => {
  return (
    <Modal show={showNewForm} onHide={() => handleCloseAddForm()}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              name="question"
              onChange={handleNewFormChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              as="textarea"
              name="answer"
              onChange={handleNewFormChange}
              rows={5}
              required
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between align-items-center mt-4 mb-4">
            <Form.Label>Published</Form.Label>
            <Form.Switch name="published" onChange={handleNewFormChange} />
          </Form.Group>
          {modalError && <div className="alert alert-danger">{modalError}</div>}

          <hr />
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button variant="primary" type="submit" disabled={isLoadingSpin}>
              Save
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
