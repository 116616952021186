import React from "react";
import { Button, Modal, Form } from "react-bootstrap";

const BulkGenerateModal = ({
  showBulkGenerateModal,
  setShowBulkGenerateModal,
  handleBulkGenerateSubmit,
  bulkCategory,
  setBulkCategory,
  categories,
  bulkCount,
  setBulkCount,
  modalError,
  isNewQrSpin,
  setModalError,
  isProduction,
  handleIsProductionChange
}) => {
  return (
    <Modal
      show={showBulkGenerateModal}
      onHide={function () {
        setShowBulkGenerateModal(false);
        setModalError("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Generate Bulk QR Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleBulkGenerateSubmit}>
          <div className="mb-3">
            <label htmlFor="bulkCategory" className="form-label">
              Category
            </label>
            <select
              id="bulkCategory"
              name="bulkCategory"
              value={bulkCategory}
              onChange={(e) => setBulkCategory(e.target.value)}
              className="form-select"
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="bulkCount" className="form-label">
              Count
            </label>
            <input
              type="number"
              id="bulkCount"
              value={bulkCount}
              onChange={(e) => setBulkCount(e.target.value)}
              className="form-control"
              min="1"
              required
            />
          </div>
          <Form.Group className="mb-3">
            <hr></hr>
            <Form.Label>Used In Production</Form.Label>
            <Form.Check
              type="switch"
              id="is-production-switch"
              name="is_production"
              checked={Boolean(isProduction)}
              onChange={handleIsProductionChange}
            />
          </Form.Group>
          <div className="container mt-1 p-0">
            {modalError && (
              <div className="alert alert-danger" role="alert">
                {modalError}
              </div>
            )}
          </div>
          <Button
            variant="primary"
            className="mt-3"
            type="submit"
            disabled={isNewQrSpin}
          >
            Generate
            {isNewQrSpin && <span className="spinner-inline"></span>}
          </Button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowBulkGenerateModal(false);
            setModalError("");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkGenerateModal;
