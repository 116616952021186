import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {Button, Badge} from "react-bootstrap";

const SettingRow = ({ setting, onEdit, onDelete }) => {
  return (
    <tr key={setting.id}>
      <td>{setting.id}</td>
      <td>{setting.title}</td>
      <td>{setting.key}</td>
      <td>{setting.value}</td>
      <td>
        {setting.show_in_config === 1 ? (
          <Badge bg="success">Yes</Badge>
        ) : (
          <Badge bg="secondary">No</Badge>
        )}
      </td>
      <td>
        {/* ====== EDIT BUTTON + MODAL ====== */}
        <Button
          variant="secondary"
          className="btn-sm me-2"
          onClick={() => onEdit(setting)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>

        <button
          className="btn btn-danger btn-sm"
          onClick={() => onDelete(setting.id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

export default SettingRow;
