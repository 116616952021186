import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionButton = ({
  tooltipMessage,
  variant = 'secondary',
  onClick,
  disabled = false,
  icon,
  className = '',
  placement = 'bottom',
  showDelay = 100,
  hideDelay = 450,
  size = 'sm'
}) => {
  return (
    <OverlayTrigger
      delay={{ hide: hideDelay, show: showDelay }}
      overlay={(props) => (
        <Tooltip {...props}>
          {tooltipMessage}
        </Tooltip>
      )}
      placement={placement}
    >
      <span 
        className="d-inline-block"
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Button
          variant={variant}
          className={`btn-${size} m-1 ${className}`.trim()}
          style={{ pointerEvents: 'all' }}
          onClick={onClick}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={icon} />
        </Button>
      </span>
    </OverlayTrigger>
  );
};

export default ActionButton;