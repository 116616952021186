import { Modal, Button, Form } from "react-bootstrap";

const AddSocialModal = ({
  showNewForm,
  handleCloseAddForm,
  handleAddSubmit,
  handleNewFormChange,
  modalError,
  setModalError,
  isLoadingSpin,
}) => {
  return (
    <Modal show={showNewForm} onHide={() => handleCloseAddForm()}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Social</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Provider</Form.Label>
            <Form.Control
              type="text"
              name="provider"
              onChange={handleNewFormChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              name="url"
              id="url"
              placeholder="https://example.com"
              pattern="https://.*"
              size="100"
              onChange={handleNewFormChange}
              required
            />
          </Form.Group>
          
          {modalError && <div className="alert alert-danger">{modalError}</div>}

          <hr />
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button variant="primary" type="submit" disabled={isLoadingSpin}>
              Save
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddSocialModal;
