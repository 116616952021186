import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./../../assets/css/DashboardLayout.css";
import axios from "axios";
import { NavLink } from "react-router-dom";
import NavMenuItem from "../nav/NavMenuItem";
import {
  faQrcode,
  faExchangeAlt,
  faShoppingCart,
  faCog,
  faLink,
  faBars,
  faQuestion,
  faPhotoVideo,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";

const DashboardLayout = () => {
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true); // New state for managing sidebar visibility

  // New function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const handleLogoutClick = async () => {
    const isConfirmed = window.confirm("Are you sure you want to log out?");
    if (isConfirmed) {
      setIsLoadingSpin(true); // Start loading
      const token = getToken();
      if (token) {
        try {
          const token = localStorage.getItem("token");

          await axios.get(process.env.REACT_APP_BASE_URL + "/logout", {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (error) {
          console.error("Logout failed", error);
          alert("Logout failed. Please try again.");
          window.location.href = "/";
        } finally {
          setIsLoadingSpin(false);

          removeToken();
          redirectToHome();
        }
      } else {
        removeToken();
        redirectToHome();
      }
    }
  };

  const navItems = [
    { to: "/dashboard/qrcodes", label: "QR Codes", icon: faQrcode },
    { to: "/dashboard/orders", label: "Orders", icon: faShoppingCart },
    { to: "/dashboard/swap", label: "Swap QR", icon: faExchangeAlt },
    {
      to: "/dashboard/memory/assign",
      label: "Assign Memories",
      icon: faPhotoVideo,
    },
    { to: "/dashboard/devices", label: "Devices", icon: faMobileAlt },
    { to: "/dashboard/socials", label: "Social Links", icon: faLink },
    { to: "/dashboard/faqs", label: "FAQs", icon: faQuestion },
    { to: "/dashboard/settings", label: "Settings", icon: faCog },
  ];

  return (
    <div
      className={`dashboard-layout d-flex ${
        isSidebarVisible ? "sidebar-visible" : "sidebar-hidden"
      }`}
    >
      <aside className="sidebar d-flex flex-column justify-content-between">
        <div className="logo-title text-center">
          <NavLink to="/dashboard">
            <img
              src={`${process.env.PUBLIC_URL}/logo2.png`}
              alt="Logo"
              className="logo mb-2"
              style={{ maxWidth: "65px" }}
            />
            <h5 className="navbar-brand">QRystal's dashboard</h5>
          </NavLink>
          <hr />
        </div>

        <nav className="flex-grow-1">
          <ul className="nav flex-column fw-normal">
            {navItems.map((item, index) => (
              <NavMenuItem
                key={index}
                to={item.to}
                label={item.label}
                icon={item.icon}
              />
            ))}
          </ul>
        </nav>
        {/* ====== Logout ====== */}
        <div className="mb-5">
          <hr />
          <button
            className="btn btn-outline-danger w-100"
            onClick={handleLogoutClick}
            disabled={isLoadingSpin}
          >
            Logout
            {isLoadingSpin && <span className="spinner-inline"></span>}
          </button>
        </div>
      </aside>
      <main
        className={`content flex-grow-1 ${
          isSidebarVisible ? "" : "sidebar-hidden"
        }`}
      >
        <button
          className="sidebar-toggle btn btn-light"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
