// apiService.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const login = async (email, password) => {
  return axios.post(`${API_BASE_URL}/login`, { email, password });
};

export const syncCategories = async (token) => {
  return axios.get(`${API_BASE_URL}/admin/categories/sync`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
