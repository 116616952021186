import React from "react";

const WelcomeMessage = () => {
  // Function to get the current date and time with the full day name
  const getCurrentDate = () => {
    const now = new Date();
    const date = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return (
      now.toLocaleDateString("en-US", date)
    );
  };
  const getCurrentTime = () => {
    const now = new Date();
    const time = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return (
      now.toLocaleTimeString("en-US", time)
    );
  };
  return (
    <div className="welcome-container">
      <img
        src={`${process.env.PUBLIC_URL}/logo2.png`} // Adjust the logo path as needed
        alt="Logo"
        className="welcome-logo"
      />
      <h2>Welcome to the Admin Dashboard</h2>
      <p>
        This is your central hub for managing the application. Please select an
        option from the menu to get started.
      </p>
      {/* Display the current date and time */}
      <hr className="mt-5"/>
      <h3><b>{getCurrentDate()}</b></h3>
      <h4>{getCurrentTime()}</h4>
    </div>
  );
};

export default WelcomeMessage;
