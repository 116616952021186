import { useEffect, useState } from "react";
import axios from "axios";
import Paginate from "../../components/shared/Paginate";
import SimpleQRRow from "../../components/qr/SimpleQRRow";
import AssignMemoryModal from "../../components/memory/AssignMemoryModal";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";

const MemoryPage = () => {
  // Set Up State Hooks
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [qrcodeIdFilter, setQrcodeIdFilter] = useState(null);

  const [currentOpenedQRCode, setCurrentOpenedQRCode] = useState({});
  const [showMemoryModal, setShowMemoryModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
  const perPageOptions = [10, 25, 50, 100];

  const handlePerPageChange = (e) => {
    const newPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  useEffect(() => {
    const fetchSimpleQRCodes = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/qrcodes",
          {
            params: {
              page: currentPage,
              qrcode_id: qrcodeIdFilter,
              page_size: itemsPerPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setCodes(response.data.content.data);
          setCurrentPage(currentPage);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch QRCodes");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchSimpleQRCodes();
  }, [currentPage, qrcodeIdFilter,itemsPerPage]); // Re-fetch when filters change

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setCurrentPage(1);
    setQrcodeIdFilter(value);
  };

  const handleAssignModal = (qrCode) => {
    setCurrentOpenedQRCode(qrCode); // Store the selected QR code's data
    setShowMemoryModal(true);

    setMemoryFormData({
      qrcode_id: qrCode.id,
      title: "",
      date: null,
      media: "",
    });

    setSelectedFiles([]);
  };

  const [memoryFormData, setMemoryFormData] = useState({
    qrcode_id: null,
    title: "",
    date: null,
    media: "",
  });

  const [selectedFiles, setSelectedFiles] = useState([]);

  const settings = JSON.parse(localStorage.getItem("settings"));
  const maxMemoryItemsCountSetting = settings.find(
    (setting) => setting.key === "max_memory_items_count"
  );
  const maxMemoryItemsCount = maxMemoryItemsCountSetting
    ? maxMemoryItemsCountSetting.value
    : "10";

  // Handle modal Form Changes
  const handleMemoryFormChange = (event) => {
    let { name, value } = event.target;

    if (name === "media") {
      const files = Array.from(event.target.files).slice(
        0,
        maxMemoryItemsCount
      );
      value = files;
      setSelectedFiles(files);
    }

    setMemoryFormData((prev) => ({ ...prev, [name]: value }));
    // setMemoryFormData((prev) => {
    //   const updatedFormData = { ...prev, [name]: value };
    //   console.log("Updated FormData:", updatedFormData); // Debug log
    //   return updatedFormData;
    // });
  };

  const handleCloseForm = () => {
    setShowMemoryModal(false);
    setModalError("");
  };

  // Handle Assign Memory Submit
  const handleMemorySubmit = async (e) => {
    e.preventDefault();

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }

    // Basic validation example
    if (
      !memoryFormData.qrcode_id ||
      !memoryFormData.title ||
      !memoryFormData.date
    ) {
      setModalError("Please fill in all required fields correctly.");
      return;
    }

    // Create a FormData object to hold the form values including the files
    const formData = new FormData();
    formData.append("qrcode_id", memoryFormData.qrcode_id);
    formData.append("title", memoryFormData.title);
    formData.append("date", memoryFormData.date);

    // Append each file to the 'media' array in the FormData
    for (const file of memoryFormData.media) {
      formData.append("media[]", file);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/memory`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful edit
      // Optionally refresh your list of QR codes here
      if (response.data.result === "success") {
        setShowMemoryModal(false); // Close the modal
        setCodes(response.data.content.data);
        setTotalPages(response.data.content.last_page);
        setLoading(false);
        setModalError("");
      }
    } catch (error) {
      console.error(
        "Error assigning a memory:",
        error.response.data.error_description[0]
      );
      setModalError(error.response.data.error_description[0]);
    }
  };

  // Render the Component
  return (
    <div className="memories-page">
      <h3 className="my-5 ">QRCode - Assign Memories</h3>
      <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-md-5">
        {/* Filters */}
        <input
          type="text"
          name="qrcode_id"
          onChange={handleFilterChange}
          className="form-control input-filters me-4"
          placeholder="Filter by QRCode ID"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Body */}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "55%" }}>Code</th>
                  <th style={{ width: "20%" }}>Options</th>
                  <th style={{ width: "20%" }}>Has Memories</th>
                </tr>
              </thead>
              <tbody>
                {codes.map((qrCode) => (
                  <SimpleQRRow
                    key={qrCode.id}
                    qrCode={qrCode}
                    assign={handleAssignModal}
                  />
                ))}
                {/* Modal */}
                <AssignMemoryModal
                  showMemoryModal={showMemoryModal}
                  handleCloseForm={handleCloseForm}
                  currentOpenedQRCode={currentOpenedQRCode}
                  handleMemorySubmit={handleMemorySubmit}
                  modalError={modalError}
                  handleMemoryFormChange={handleMemoryFormChange}
                  selectedFiles={selectedFiles}
                  maxMemoryItemsCount={maxMemoryItemsCount}
                />
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <label htmlFor="perPageSelect" className="me-2">
                Items per page:
              </label>
              <select
                id="perPageSelect"
                value={itemsPerPage}
                onChange={handlePerPageChange}
                className="form-select form-select-sm d-inline-block w-auto"
              >
                {perPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <nav aria-label="Page navigation">
                <ul className="pagination flex-wrap">
                  <Paginate
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    className="pagination-sm flex-wrap"
                  />
                </ul>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MemoryPage;
