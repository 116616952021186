import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faEdit,
  faQrcode,
  faTrash,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import CodeWithCopy from "./CodeWithCopy";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ActionButton from "./components/ActionButton";
const QRRow = ({
  key,
  qrCode,
  onEdit,
  onDelete,
  resetEmail,
  onChange,
  isSelected,
}) => {
  return (
    <tr key={qrCode.id}>
      <td>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onChange(qrCode.id)}
        />
      </td>
      <td>{qrCode.id}</td>
      <td>
        <CodeWithCopy code={qrCode.code} />
      </td>
      <td>{qrCode.reset_email ?? "N/A"}</td>
      <td>{qrCode.passcode_type}</td>
      <td>{qrCode.category_name}</td>
      <td>
        {qrCode.is_assigned === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        {qrCode.is_printed === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        {qrCode.is_scanned === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        {qrCode.is_production === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        <Link to={qrCode.qr_image_url} className="link-secondary me-3">
          <FontAwesomeIcon
            icon={faQrcode}
            style={{ cursor: "pointer" }}
            title="Open QR Image"
            size="2x"
          />
        </Link>
        <Link to={qrCode.barcode_image_url} className="link-secondary">
          <FontAwesomeIcon
            icon={faBarcode}
            style={{ cursor: "pointer" }}
            title="Open Barcode Image"
            size="2x"
          />
        </Link>
      </td>
      <td>
        <ActionButton
          tooltipMessage="Reset Passcode By Email"
          variant="secondary"
          onClick={() => resetEmail(qrCode.id)}
          disabled={!qrCode.reset_email}
          icon={faEnvelope}
        />

        {/* ====== EDIT BUTTON + MODAL ====== */}
        <ActionButton
          tooltipMessage="Edit"
          variant="secondary"
          onClick={() => onEdit(qrCode)}
          icon={faEdit}
        />
        <ActionButton
          tooltipMessage="Delete"
          className="btn btn-danger btn-sm"
          onClick={() => onDelete(qrCode.id)}
          icon={faTrash}
        />
      </td>
    </tr>
  );
};

export default QRRow;
