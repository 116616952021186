import { Pagination } from "react-bootstrap";

const Paginate = ({ setCurrentPage, currentPage, totalPages }) => {
  const MAX_VISIBLE_PAGES = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
  );
  const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

  const pageItems = [];
  for (let i = startPage; i <= endPage; i++) {
    pageItems.push(
      <Pagination.Item
        key={i}
        active={currentPage === i}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="flex-wrap justify-content-center">
      <Pagination.First
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
      />
      {startPage > 1 && <Pagination.Ellipsis />}
      {pageItems}
      {endPage < totalPages && <Pagination.Ellipsis />}
      <Pagination.Next
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default Paginate;
