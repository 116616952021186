import React from "react";

const OrderTabs = ({ activeTab, onTabChange, unassignedCount = 0 }) => {
  return (
    <div className="my-5">
      <ul className="nav nav-pills nav-fill bg-light rounded p-4">
        <li className="nav-item mx-4">
          <button
            className={`nav-link ${
              activeTab === "all" ? "active" : ""
            } shadow-sm`}
            onClick={() => onTabChange("all")}
          >
            <span>All Orders</span>
          </button>
        </li>

        <li className="nav-item mx-4">
          <button
            className={`nav-link ${
              activeTab === "unassigned" ? "active" : ""
            } shadow-sm position-relative`}
            onClick={() => onTabChange("unassigned")}
          >
            <span>Unassigned Orders</span>
            {unassignedCount > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {unassignedCount}
                <span className="visually-hidden">unassigned orders</span>
              </span>
            )}
          </button>
        </li>
        <li className="nav-item mx-4">
          <button
            className={`nav-link ${
              activeTab === "assigned" ? "active" : ""
            } shadow-sm`}
            onClick={() => onTabChange("assigned")}
          >
            <span>Assigned Orders</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default OrderTabs;
