import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

const AssignMemoryModal = ({
  showMemoryModal,
  handleCloseForm,
  currentOpenedQRCode,
  handleMemorySubmit,
  modalError,
  handleMemoryFormChange,
  selectedFiles,
  maxMemoryItemsCount,
}) => {
  const [date, setDate] = useState();
  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  return (
    <Modal show={showMemoryModal} onHide={() => handleCloseForm()}>
      <Modal.Header closeButton>
        <Modal.Title>
          Assign Memories For QRCode id: ({currentOpenedQRCode.id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleMemorySubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              onChange={handleMemoryFormChange}
            />
          </Form.Group>
          <Form.Group className="my-2">
            <Form.Label>Date</Form.Label>
            <DatePicker
              showIcon
              isClearable
              style={{ display: "block" }}
              selected={date}
              name="date"
              required
              // DatePicker return the value directly, so we need to convert it to event
              onChange={(selectedDate) => {

                const formattedDate = formatDate(selectedDate);

                setDate(selectedDate);
                handleMemoryFormChange({
                  target: { name: "date", value: formattedDate },
                });
              }}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="my-5">
            <Form.Label>Media</Form.Label>
            <Form.Control
              type="file"
              name="media"
              multiple
              required
              onChange={handleMemoryFormChange}
              accept="image/jpeg, image/png, image/gif, image/webp, video/mp4, video/mkv, video/avi, video/m4v, video/webm, video/mov"
            />
            <div className="mt-2">
              {selectedFiles.map((file, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt="thumbnail"
                  style={{ width: "50px", height: "50px", marginRight: "5px" }}
                />
              ))}
            </div>
            <div>
              Selected: {selectedFiles.length}/{maxMemoryItemsCount}
            </div>
          </Form.Group>
          {modalError && (
            <div className="alert alert-danger my-3">{modalError}</div>
          )}
          <Button
            variant="primary"
            type="submit"
            style={{
              margin: "20px 0",
              float: "right",
            }}
          >
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssignMemoryModal;
