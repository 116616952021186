// retryUtility.js
export const retryAsyncFunction = async (
  fn,
  retriesLeft = 2,
  interval = 1000
) => {
  try {
    const result = await fn();
    return result;
  } catch (error) {
    if (retriesLeft) {
      setTimeout(
        () => retryAsyncFunction(fn, retriesLeft - 1, interval),
        interval
      );
    }
    // else {
    //   throw new Error("Max retries reached");
    // }
  }
};
