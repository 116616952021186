export const getToken = () => {
  const token = localStorage.getItem("token");
  const tokenExpire = localStorage.getItem("token_expire");
  // Parse tokenExpire to a number since localStorage stores everything as strings
  const tokenExpireMillis = parseInt(tokenExpire, 10);

  if (!token || !tokenExpire || new Date().getTime() > tokenExpireMillis) {
    removeToken();
    return null;
  }
  return token;
};

// Modifying isAuthenticated function
export const isAuthenticated = () => {
  return getToken() !== null;
};

export const removeToken = () => {
  // alert("You are not authenticated. Please login.");
  localStorage.removeItem("token");
  localStorage.removeItem("token_expire");
};

export const redirectToHome = () => {
  window.location.href = "/";
};