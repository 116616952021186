import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const EditFaqModal = ({
  showEditModal,
  handleCloseEditForm,
  currentOpenedItem,
  handleEditFormSubmit,
  editFormData,
  handleEditFormChange,
  editFormError,
  setEditFormError,
  isLoadingSpin,
}) => {
  return (
    <Modal show={showEditModal} onHide={() => handleCloseEditForm()}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Question ({currentOpenedItem.id})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleEditFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              name="question"
              value={editFormData.question}
              onChange={handleEditFormChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              as="textarea"
              name="answer"
              value={editFormData.answer}
              onChange={handleEditFormChange}
              rows={5}
              required
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between align-items-center mt-4 mb-4">
            <Form.Label>Published</Form.Label>
            <Form.Switch
              name="published"
              value={editFormData.published}
              onChange={handleEditFormChange}
              checked={editFormData.published}
            />
          </Form.Group>
          {editFormError && (
            <div className="alert alert-danger">{editFormError}</div>
          )}
          <hr />
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button variant="primary" type="submit" disabled={isLoadingSpin}>
              Update
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditFaqModal;
