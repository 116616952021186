import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SelectPasscodeType from "./components/SelectPasscodeType";

const EditQRModal = ({
  showEditModal,
  setShowEditModal,
  currentOpenedQRCode,
  handleEditFormSubmit,
  editFormData,
  handleEditFormChange,
  categories,
  passcode_types,
  editFormError,
}) => {
  return (
    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit QR Code ({currentOpenedQRCode.id})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          <b>QRCode: </b>
          {currentOpenedQRCode.code}
        </Form.Label>
        <hr />
        <Form onSubmit={handleEditFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Select
              name="category_id"
              value={editFormData.category_id}
              onChange={handleEditFormChange}
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* <SelectPasscodeType
            editFormData={editFormData}
            handleEditFormChange={handleEditFormChange}
            passcode_types={passcode_types}
          /> */}
          {editFormData.passcode_type === "Passcode" && (
            <Form.Group className="mb-3">
              <Form.Label>Passcode</Form.Label>
              <Form.Control
                type="text"
                pattern="^[0-9]{1,4}$"
                name="passcode"
                placeholder="****"
                value={editFormData.passcode}
                onChange={handleEditFormChange}
                required={editFormData.passcode_type === "Passcode"}
              />
              <Form.Text className="text-muted">
                Enter a passcode (up to 4 digits).
              </Form.Text>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <hr></hr>
            <Form.Label>Used In Production</Form.Label>
            <Form.Check
              type="switch"
              name="is_production"
              checked={Boolean(editFormData.is_production)}
              onChange={handleEditFormChange}
            />
          </Form.Group>
          {editFormError && (
            <div className="alert alert-danger">{editFormError}</div>
          )}
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditQRModal;
