import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Paginate from "../../components/shared/Paginate";
import SocialRow from "../../components/social/SocialRow";
import AddSocialModal from "../../components/social/AddSocialModal";
import EditSocialModal from "../../components/social/EditSocialModal";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";

const SocialPage = () => {
  // Set Up State Hooks
  const [socialLinks, setSocialLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [editFormError, setEditFormError] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  // NEW Form state
  const [showNewForm, setShowNewForm] = useState(false);

  const handleCloseAddForm = () => {
    setShowNewForm(false);
    setModalError(""); // Resetting modal error when modal is closed
  };

  const [newLink, setNewLink] = useState({
    provider: "",
    url: "",
  });
  // Edit & Delete options states
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditForm = () => {
    setShowEditModal(false);
    setModalError(""); // Resetting modal error when modal is closed
  };

  const [currentOpenedItem, setCurrentOpenedItem] = useState({});
  const [editFormData, setEditFormData] = useState({
    id: null,
    provider: "",
    url: "",
  });

  const [updateTrigger, setUpdateTrigger] = useState(0); // Add this line

  // Fetch socialLinks on Component Mount
  useEffect(() => {
    const fetchSocialLinks = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/social",
          {
            params: {
              search: searchQuery,
              page: currentPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setSocialLinks(response.data.content.data);
          setCurrentPage(currentPage);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch Social Links");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchSocialLinks();
  }, [searchQuery, currentPage, updateTrigger]);

  // Implement Filter Handling
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  // Handle Add Setting Form Submit
  const handleAddSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsLoadingSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/social`,
        newLink,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful submission
      if (response.data.result === "success") {
        setUpdateTrigger((prev) => prev + 1);
        setTotalPages(response.data.content.last_page);
        setCurrentPage(currentPage);
        setLoading(false);
        handleCloseAddForm();
      }
    } catch (modalError) {
      // Handle errors, e.g., show an error message
      console.log(modalError.response.data.error_description[0]);
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsLoadingSpin(false);
    }
  };

  // Implement Filter Handling
  const handleNewFormChange = (e) => {
    const { name, value } = e.target;

    setNewLink((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Edit QR Form Changes
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;

    setEditFormData((prev) => ({ ...prev, [name]: value }));
    console.log("after " + name + ":" + value);
  };

  const handleShowEditModal = (link) => {
    setCurrentOpenedItem(link);
    setShowEditModal(true);

    setEditFormData({
      id: link.id,
      provider: link.provider,
      url: link.url,
    });
  };

  // Handle Edit Form Submit
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    // Basic validation example
    if (!editFormData.provider || !editFormData.url) {
      setEditFormError("Please fill in all required fields correctly.");
      return;
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/social/${editFormData.id}`,
        {
          provider: editFormData.provider,
          url: editFormData.url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful edit
      if (response.data.result === "success") {
        setUpdateTrigger((prev) => prev + 1);

        setTotalPages(response.data.content.last_page);
        setCurrentPage(currentPage);
        setLoading(false);
      }
    } catch (error) {
      setEditFormError("Failed to update. Please try again.");
    } finally {
      handleCloseEditForm();
    }
  };

  // Handle Delete QR
  const handleDelete = async (id) => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/admin/social/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.result === "success") {
          // console.log(currentPage);
          setUpdateTrigger((prev) => prev + 1);

          setTotalPages(response.data.content.last_page);
          setCurrentPage(currentPage);
          setLoading(false);
        }
      } catch (error) {
        console.error("Delete failed", error);
        alert("Failed to delete social link");
      }
    }
  };

  // Render the Component
  return (
    <div className="social-page">
      <h3 className="my-5">Social Links</h3>

      {/* ======== New Social Link BUTTON ========= */}
      <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between mb-md-5">
        <Button
          className="mb-3 mb-md-0"
          variant="secondary"
          onClick={() => setShowNewForm(true)}
        >
          New Social Link
        </Button>
        <AddSocialModal
          showNewForm={showNewForm}
          handleCloseAddForm={handleCloseAddForm}
          handleAddSubmit={handleAddSubmit}
          handleNewFormChange={handleNewFormChange}
          modalError={modalError}
          setModalError={setModalError}
          isLoadingSpin={isLoadingSpin}
        />

        {/* Filters */}
        <input
          type="text"
          name="search"
          onChange={handleSearchChange}
          className="form-control input-filters"
          placeholder="Type here to search"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Body */}
          <div className="table-responsive">
            <table className="table table-responsive overflow">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "10%" }}>Provider</th>
                  <th style={{ width: "auto" }}>URL</th>
                  <th style={{ width: "10%" }}>Options</th>
                </tr>
              </thead>
              <tbody>
                {socialLinks.map((link) => (
                  <SocialRow
                    key={link.id}
                    link={link}
                    onEdit={handleShowEditModal}
                    onDelete={handleDelete}
                  />
                ))}
                {/* Edit Modal */}
                <EditSocialModal
                  showEditModal={showEditModal}
                  handleCloseEditForm={handleCloseEditForm}
                  currentOpenedItem={currentOpenedItem}
                  handleEditFormSubmit={handleEditFormSubmit}
                  editFormData={editFormData}
                  handleEditFormChange={handleEditFormChange}
                  editFormError={editFormError}
                  setEditFormError={setEditFormError}
                  isLoadingSpin={isLoadingSpin}
                />
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <nav aria-label="Page navigation" className="overflow-auto">
              <Paginate
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default SocialPage;
