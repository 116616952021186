import { Button } from "react-bootstrap";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate(); // Hook to access the navigate function

  return (
    <div className="container-fluid h-100 d-flex flex-column justify-content-center align-items-center min-vh-100">
      <div className="logo-title text-center">
        <NavLink to="/dashboard">
          <img
            src={`${process.env.PUBLIC_URL}/logo2.png`}
            alt="Logo"
            className="logo mb-2"
            style={{ maxWidth: "100px" }}
          />
        </NavLink>
      </div>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1 className="fw-bold">404</h1>
        <p>Page Not Found</p>
        <Button variant="secondary" onClick={() => navigate("/")}>
          Return to Home
        </Button>
        {/* Button to navigate back to home */}
      </div>
    </div>
  );
};

export default NotFoundPage;
