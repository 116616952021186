import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const EditSocialModal = ({
  showEditModal,
  handleCloseEditForm,
  currentOpenedItem,
  handleEditFormSubmit,
  editFormData,
  handleEditFormChange,
  editFormError,
  setEditFormError,
  isLoadingSpin,
}) => {
  return (
    <Modal show={showEditModal} onHide={() => handleCloseEditForm()}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Social Link ({currentOpenedItem.id})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleEditFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Provider</Form.Label>
            <Form.Control
              type="text"
              name="provider"
              value={editFormData.provider}
              onChange={handleEditFormChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>URL</Form.Label>
             <Form.Control
              type="url"
              name="url"
              id="url"
              pattern="https://.*"
              size="100"
              value={editFormData.url}
              onChange={handleEditFormChange}
              required
            />
          </Form.Group>
          {editFormError && (
            <div className="alert alert-danger">{editFormError}</div>
          )}
          <hr />
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button variant="primary" type="submit" disabled={isLoadingSpin}>
              Update
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditSocialModal;
